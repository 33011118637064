<template>
  <div class="layout-default">
    <AppCookieConsent v-if="!route?.query?._storyblok" />

    <Transition name="fade-in">
      <ShopCartPopup v-if="isCartStoreOpen" />
    </Transition>

    <Transition name="fade-in">
      <ShopProductDeliveryPopup v-if="isDeliveryStoreOpen" />
    </Transition>

    <Transition name="fade-in">
      <ShopProductAvailabilityPopup v-if="isAvailabilityStoreOpen" />
    </Transition>

    <AppHeader />

    <slot />

    <NuxtLazyHydrate :when-visible="{ rootMargin: '50px' }">
      <AppFooter />
    </NuxtLazyHydrate>

    <Transition name="slide-down">
      <AppAlertBox v-if="isAlertBoxOpen" />
    </Transition>
  </div>
</template>

<script setup>

/*
  Initialize Cart
*/
const cartPopupStore = useCartPopupStore();
const isCartStoreOpen = computed(() => cartPopupStore.getRenderState);

/*
  Initialize Delivery
*/
const deliveryPopupStore = useDeliveryPopupStore();
const isDeliveryStoreOpen = computed(() => deliveryPopupStore.getRenderState);

/*
  Initialize Delivery
*/
const availabilityPopupStore = useAvailabilityPopupStore();
const isAvailabilityStoreOpen = computed(() => availabilityPopupStore.getRenderState);

/*
  Initialize Alertbox
*/
const alertBoxStore = useAlertBoxStore();
const isAlertBoxOpen = computed(() => alertBoxStore.isOpen);

/*
  Initialize GTM
*/
const { sendEvent } = useGTM();

/*
  Initialize cart
*/
const route = useRoute();
onMounted(async () => {
    const { clearCart } = route.query;
    const cartStore = useCartStore();
    cartStore.initStrapiClient();

    if (clearCart) {
        await cartStore.clearCart(false);
        const order = await cartStore.getLastOrder();
        if (order) {
            sendEvent({
                event: 'purchase',
                ecommerce: order,
            });
        }
    }

    cartStore.initializeCart();

    /* Setup payment links */
    const checkoutStore = useCheckoutStore();
    checkoutStore.setUrls();
});

/*
  Initialize anchor store / reset anchors on route change
*/
const anchorStore = useAnchorStore();

watch(() => route.path, () => {
    anchorStore.resetAnchors();
});
</script>
